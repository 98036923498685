import { themeGet } from "@styled-system/theme-get"
import React from "react"
import styled from "styled-components"
import { footerNav } from "../../data/site"
import { Box } from "../core"
import { Container } from "../core/container"
import { IconInstagram, IconTwitter } from "../icons"
import { LemonLabWordMark } from "../icons/logo"
import { SmartLink } from "../smartlink"

export function Footer({ wide }) {
  return (
    <FooterWrapper>
      <InnerWrapper wide={wide}>
        <LogoWrapper>
          <LemonLabDot />
          <LemonLabWordMark />
        </LogoWrapper>
        <LowerWrapper>
          <Box>
            <Box mt={["8px"]}>
              Lemon Lab combines investigative journalism and real reviews to build a place on the internet where you
              can find quality health, wellness and tech products.
            </Box>
            <Box mt={["14px"]}>More tests, fewer lemons.</Box>
          </Box>
          <Box display="flex" mt={["24px", "24px", "0"]}>
            <SocialIcon href="https://twitter.com/Lemonlabco">
              <IconTwitter />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/lemonlabco/">
              <IconInstagram />
            </SocialIcon>
          </Box>
          <Box display={["none", "none", "block"]}>
            {footerNav.learnMore.map(({ label, path, external }) => (
              <Box key={label}>
                <SmartLink label={label} path={path} external={external} />
              </Box>
            ))}
          </Box>
        </LowerWrapper>
      </InnerWrapper>
    </FooterWrapper>
  )
}

const FooterWrapper = styled(Box).attrs(() => ({
  as: "footer",
  fontSize: [2],
}))`
  width: 100%;
  background: ${themeGet("colors.black")};
  color: white;

  a {
    color: white;

    &:hover {
      color: ${themeGet("colors.accent.hover")};
    }
  }
`

const InnerWrapper = styled(Container).attrs(() => ({
  pt: ["40px"],
  pb: ["80px"],
}))``

const LogoWrapper = styled(Box).attrs(() => ({}))`
  display: flex;
  align-items: center;

  svg {
    color: ${themeGet("colors.accent.default")};
    height: 16px;
    width: auto;
  }
`

const LemonLabDot = styled(Box).attrs(() => ({
  width: ["24px"],
  height: ["24px"],
  mr: ["12px"],
}))`
  border: 2px solid ${themeGet("colors.accent.default")};
  border-radius: 200px;
  transition: background 200ms linear;
`

const LowerWrapper = styled(Box).attrs(() => ({
  py: ["12x"],
  pl: ["36px"],
}))`
  @media (min-width: ${themeGet("breakpoints.md")}) {
    display: grid;
    grid-gap: 64px;
    grid-template-columns: 2fr 1fr 1fr;
  }
`

const SocialIcon = styled(Box).attrs(() => ({
  as: "a",
  width: ["48px"],
  height: ["48px"],
  mr: ["12px"],
}))`
  border-radius: 100px;
  border: 2px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
`

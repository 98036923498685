import { themeGet } from "@styled-system/theme-get"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { desktopNav } from "../../data/site"
import { Box } from "../core"
import { LemonLabWordMark } from "../icons/logo"
import { SmartLink } from "../smartlink"
import { MobileNav } from "./mobile-nav"
import { LinksWrapper } from "./nav"

export function LargeNav({ desktopLinks }) {
  return (
    <Wrapper>
      <LeftWrapper>
        <Box display={["none", "block"]}>
          {desktopNav.menu.map(({ label, path, external }) => (
            <SmartLink key={label} label={label} path={path} external={external} />
          ))}
        </Box>
      </LeftWrapper>
      <Link to="/">
        <CenterWrapper>
          <LemonLabDot />
          <LogoTextWrapper>
            <LemonLabWordMark />
          </LogoTextWrapper>
        </CenterWrapper>
      </Link>
      <RightWrapper>
        <Box display={["none", "block"]}>
          <SmartLink label={desktopNav.primaryCta.label} path={desktopNav.primaryCta.path} />
        </Box>
        <MobileNav />
      </RightWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Box).attrs(() => ({
  height: ["108px"],
  px: ["24px", "24px", "32px", "64px"],
  marginBottom: ["80px"],
  fontSize: 2,
}))`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  max-width: ${themeGet("breakpoints.xl")};
  margin-left: auto;
  margin-right: auto;
`

const CenterWrapper = styled(Box).attrs(() => ({
  width: ["160px", "200px"],
  px: ["16px", "20px"],
}))`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "white";
`

const LogoTextWrapper = styled(Box).attrs(() => ({
  marginTop: ["-20px", "-26px"],
}))`
  position: absolute;
  top: 100%;
  width: 100%;
  padding: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: ${themeGet("colors.black")};
    width: 94%;
    margin-top: 12px;
    margin-bottom: 4px;
    height: auto;
  }
`

const LemonLabDot = styled(Box).attrs(() => ({
  width: ["48px", "56px"],
  height: ["48px", "56px"],
}))`
  background: ${themeGet("colors.accent.default")};
  border: 2px solid ${themeGet("colors.black")};
  border-radius: 200px;
  transition: background 200ms linear;

  ${CenterWrapper}:active & {
    background: ${themeGet("colors.accent.pressed")};
  }
`

const LemonLabTagline = styled(Box).attrs(() => ({
  paddingTop: ["2px", "4px"],
  fontSize: [1, 2],
  fontWeight: 1,
}))`
  text-align: center;
  color: ${themeGet("colors.black")};
  white-space: nowrap;
`

const SideWrapper = styled(LinksWrapper)`
  border-bottom: 2px solid ${themeGet("colors.black")};
`

const LeftWrapper = styled(SideWrapper)``

const RightWrapper = styled(SideWrapper)`
  justify-content: flex-end;
`
